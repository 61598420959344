'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import Image from 'next/image';
import { useTranslations } from 'next-intl';

import { useEffect, useState } from 'react';

import { useFormStatus } from 'react-dom';
import { useForm, useFormContext } from 'react-hook-form';

import {
  SocialRegisterPayload,
  socialRegisterSchema,
} from '@/app/[locale]/(auth)/sign-up/(funnel)/register/types';
import { Button } from '@/app/_components/ui/button';
import { Checkbox } from '@/app/_components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/app/_components/ui/form';
import { Separator } from '@/app/_components/ui/separator';
import { SocialAuth } from '@/domain/user/services/types';

import Link from '@/lib/translation/components/link';

import { getSocialAuthListAction, getSocialAuthRedirectUrl } from './actions';

const SocialRegisterForm = () => {
  const t = useTranslations();

  const form = useFormContext();
  const { pending } = useFormStatus();

  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="acceptRiskCgu"
        disabled={pending}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <div className="flex flex-row gap-4">
                <Checkbox
                  id={field.name}
                  name={field.name}
                  checked={field.value === 'on'}
                  onCheckedChange={(value) => {
                    field.onChange(value === true ? 'on' : null);
                  }}
                  data-testid="register-cgu-checkbox"
                />

                <label
                  htmlFor={field.name}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {t('auth.signUp.steps.register.form.acceptRiskCgu')}

                  <Link
                    href="https://sowefund.com/cgu"
                    className="text-primary underline"
                    target="_blank"
                  >
                    {t('auth.signUp.steps.register.form.acceptRiskCguLabel')}
                  </Link>
                </label>
              </div>
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="acceptRiskWarning"
        disabled={pending}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <div className="flex flex-row gap-4">
                <Checkbox
                  id={field.name}
                  name={field.name}
                  checked={field.value === 'on'}
                  onCheckedChange={(value) => {
                    field.onChange(value === true ? 'on' : 'off');
                  }}
                  data-testid="register-risk-checkbox"
                />

                <label
                  htmlFor={field.name}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {t('auth.signUp.steps.register.form.acceptRiskWarning')}
                  <Link
                    href="https://sowefund.com/mentions-legales#risques-des-investissements-en-actions-non-cotees-de-societes-en-phase-damorcage"
                    className="text-primary underline"
                    target="_blank"
                  >
                    {t(
                      'auth.signUp.steps.register.form.acceptRiskWarningLabel',
                    )}
                  </Link>
                </label>
              </div>
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
};

interface SocialAuthListProps {
  callbackUrl: string;
  sectionTitle: string;
  context?: 'signUp' | 'signIn';
}

const SocialAuthList = ({
  callbackUrl,
  sectionTitle,
  context = 'signIn',
}: SocialAuthListProps) => {
  const t = useTranslations();

  const [socialAuthList, setSocialAuthList] = useState<SocialAuth[]>([]);
  const [socialAuthRedirectError, setSocialAuthRedirectError] =
    useState<boolean>(false);

  const getSocialAuthList = async () => {
    const socialAuths = await getSocialAuthListAction();
    setSocialAuthList(socialAuths);
  };

  const signInWithSocialAuth = async (socialAuth: SocialAuth) => {
    setSocialAuthRedirectError(false);

    try {
      const signInUrl = await getSocialAuthRedirectUrl({
        key: socialAuth.key,
        callbackUrl,
      });
      window.location.assign(signInUrl);
    } catch (error) {
      setSocialAuthRedirectError(true);
    }
  };

  const form = useForm<SocialRegisterPayload>({
    resolver: zodResolver(socialRegisterSchema(t)),
    defaultValues: {
      acceptRiskWarning: 'off',
      acceptRiskCgu: 'off',
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    getSocialAuthList();
  }, []);

  if (!socialAuthList?.length) {
    return null;
  }

  return (
    <>
      <div className="my-4 flex w-full flex-row items-center">
        <Separator className="shrink" />
        <div className="mx-4 text-nowrap text-lg">{sectionTitle}</div>
        <Separator className="shrink" />
      </div>

      {context === 'signUp' ? (
        <div className="flex flex-col gap-4">
          <p>{t('auth.signUp.steps.register.form.socialAcceptRiskAndCgu')}</p>
          <Form {...form}>
            <form>
              <SocialRegisterForm />
            </form>
          </Form>
          <div className="flex w-full flex-col gap-4 lg:flex-row">
            {socialAuthList.map((socialAuth) => {
              return (
                <Button
                  key={socialAuth.key}
                  variant="ghost"
                  className="w-full gap-4 rounded-full border px-8 py-2 "
                  onClick={() => signInWithSocialAuth(socialAuth)}
                  disabled={!form.formState.isValid}
                >
                  <Image
                    src={socialAuth.logo}
                    width={25}
                    height={25}
                    alt={socialAuth.label}
                  />
                  <p>{socialAuth.label}</p>
                </Button>
              );
            })}
            {socialAuthRedirectError ? (
              <p className="text-center text-sm text-destructive">
                {t('error.errorOccured')}
              </p>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col gap-4 lg:flex-row">
          {socialAuthList.map((socialAuth) => {
            return (
              <Button
                key={socialAuth.key}
                variant="ghost"
                className="w-full gap-4 rounded-full border px-8 py-2 "
                onClick={() => signInWithSocialAuth(socialAuth)}
              >
                <Image
                  src={socialAuth.logo}
                  width={25}
                  height={25}
                  alt={socialAuth.label}
                />
                <p>{socialAuth.label}</p>
              </Button>
            );
          })}
          {socialAuthRedirectError ? (
            <p className="text-center text-sm text-destructive">
              {t('error.errorOccured')}
            </p>
          ) : null}
        </div>
      )}
    </>
  );
};

export default SocialAuthList;
